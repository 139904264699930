<template>
  <b>Motinet</b>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&family=Work+Sans:ital,wght@1,800&display=swap");
#app {
  font-family: "Fugaz One", cursive;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  color: #fca311;
  font-size: 90px;
  margin-top: 20px;
}
</style>
